var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Grid',{ref:"grid",attrs:{"enable-filters":false,"enable-pagination":true,"headers":_vm.headers,"data-store-action":"bulk_request/fetchData","form-name":"background-jobs-form"},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getJobTypeBy(item.action, item.update_values)))])]}},{key:"item.row_count",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.row_count))])]}},{key:"item.progress",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.progress !== 100 ? item.progress + ' %' : '-'))])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.created_at))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('Chip',{attrs:{"chip":_vm.getPropsBy(item.status)}})]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.id))])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }