<template>
  <Grid
    ref="grid"
    :enable-filters="false"
    :enable-pagination="true"
    :headers="headers"
    data-store-action="bulk_request/fetchData"
    form-name="background-jobs-form"
  >
    <template v-slot:[`item.action`]="{ item }">
      <span>{{ getJobTypeBy(item.action, item.update_values) }}</span>
    </template>
    <template v-slot:[`item.row_count`]="{ item }">
      <span>{{ item.row_count }}</span>
    </template>
    <template v-slot:[`item.progress`]="{ item }">
      <span>{{ item.progress !== 100 ? item.progress + ' %' : '-' }}</span>
    </template>
    <template v-slot:[`item.created_at`]="{ item }">
      <span>{{ item.created_at }}</span>
    </template>
    <template v-slot:[`item.status`]="{ item }">
      <Chip :chip="getPropsBy(item.status)" />
    </template>
    <template v-slot:[`item.id`]="{ item }">
      <span>{{ item.id }}</span>
    </template>
  </Grid>
</template>

<script>
import Grid from '@/components/grid/Grid'
import Chip from '@/components/others/Chip'
import GridBaseMixin from '@/components/grid/GridBaseMixin'

export default {
  mixins: [GridBaseMixin],
  components: {
    Grid,
    Chip
  },
  data() {
    return {
      headers: [
        { text: this.$t('background_jobs.action'), value: 'action', sortable: false, align: 'start' },
        { text: this.$t('background_jobs.row_count'), value: 'row_count', sortable: false, align: 'right' },
        { text: this.$t('background_jobs.progress_header'), value: 'progress', sortable: false, align: 'right' },
        { text: this.$t('background_jobs.created_at'), value: 'created_at', sortable: false, align: 'start' },
        { text: this.$t('background_jobs.status'), value: 'status', sortable: false, align: 'start' },
        { text: this.$t('background_jobs.id'), value: 'id', sortable: false, align: 'start' }
      ],
      data: {
        total: 0
      }
    }
  },
  methods: {
    getPropsBy(status) {
      if (status === 'success') {
        return { text: this.$t('background_jobs.done'), color: 'success' }
      } else if (status === 'new') {
        return { text: this.$t('background_jobs.pending'), color: 'info' }
      } else if (status === 'build_backlog' || status === 'chunking' || status === 'processing_chunks') {
        return { text: this.$t('background_jobs.progress'), color: 'warning' }
      } else if (status === 'skipped' || status === 'error') {
        return { text: this.$t('background_jobs.failed'), color: 'error' }
      } else {
        return status
      }
    },
    getJobTypeBy(action, update_values) {
      if (action === 'edit' && typeof update_values.folder === 'number' && Object.keys(update_values).length === 1) {
        return this.$t('background_jobs.bulk_move')
      } else if (action === 'edit') {
        return this.$t('background_jobs.bulk_edit')
      } else if (action === 'delete') {
        return this.$t('background_jobs.bulk_delete')
      } else {
        return action
      }
    }
  }
}
</script>
