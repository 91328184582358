<template>
  <div class="job-status">
    <v-chip small :color="$props.chip.color">
      {{ $props.chip.text }}
    </v-chip>
  </div>
</template>

<script>
export default {
  props: {
    chip: {
      Object,
      required: true
    }
  }
}
</script>

<style lang="sass">
@import '~@/sass/variables.sass'

.job-status
  .v-chip
    padding: 0 10px
  .v-chip.v-size--small
    border-radius: 10px
</style>
