<template>
  <div id="background-jobs">
    <Headline />
    <BackgroundJobsIndex />
  </div>
</template>

<script>
import Headline from '@/components/app/Headline.vue'
import BackgroundJobsIndex from '@/components/tools/background-jobs/BackgroundJobsIndex'

export default {
  components: {
    Headline,
    BackgroundJobsIndex
  }
}
</script>
